import React from "react"
import { Row, Col, Container } from "react-bootstrap"

//Import Components
import ContactUsSection from "components/ContactUsSection/index"
import OurPartnersSection from "components/OurPartnersSection/CustomerLogos"

// Import icons
import style from "./style.module.scss"
import svgCloud from "styles/HomeIcons/cloud.svg"
import svgQuallity from "styles/HomeIcons/quallity.svg"
import svgRealTime from "styles/HomeIcons/realTime.svg"
import svgSetup from "styles/HomeIcons/setup.svg"
import svgStandards from "styles/HomeIcons/standards.svg"

import Slider from "components/Slider/Image"

import Layout from "components/Layout/de"

//Import Video
import video from "./bg.mp4"

export default function(props) {
  const header = {
    x: 38,
    y: 56,
    text: "Qualität zählt.",
    description:
      "Deep Learning basierte visuelle Inspection für schwierige Sichtkontrollen.",
    image: video,
    big: true,
  }
  return (
    <Layout header={header} location={props.location}>
      <section className="grey-background pt-5">
        <Container>
          <Row className="pt-5 row-eq-height">
            <Col>
              <h3>
                Mit der intelligenten visuellen Erkennung von Moonvision
                automatisieren wir Ihre Qualitätskontrolle in:
              </h3>
              <ul>
                <li className={style.list_style}>Montage</li>
                <li className={style.list_style}>Metallverarbeitung</li>
                <li className={style.list_style}>Schweißverfahren</li>
                <li className={style.list_style}>Holzverarbeitung</li>
                <li className={style.list_style}>Lackiererei</li>
              </ul>
            </Col>
            <Col md="6" className={style.benefits}>
              <h3>Sie profitieren von:</h3>
              <Row>
                <Col md="6">
                  <img
                    src={svgRealTime}
                    width={60}
                    alt="illustration realtime"
                  />
                  <h4>Erkennung in Echtzeit</h4>
                </Col>
                <Col md="6">
                  <img src={svgCloud} width={60} alt="illustration cloud" />
                  <h4>
                    Anwendung sowohl über die Cloud als auch ausfallsicher vor
                    Ort
                  </h4>
                </Col>
                <Col md="6">
                  <img src={svgSetup} width={60} alt="illustration setup" />
                  <h4>Reduzierte Setup-Zeit</h4>
                </Col>
                <Col md="6">
                  <img
                    src={svgQuallity}
                    width={60}
                    alt="illustration quality"
                  />
                  <h4>Zuverlässige und gleichbleibend hohe Qualität</h4>
                </Col>
                <Col md={{ span: 6, offset: 3 }}>
                  <img
                    src={svgStandards}
                    width={60}
                    alt="illustration standards"
                  />
                  <h4>Einhaltung von Industrie-Standards</h4>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row className="pt-5">
          <Col className="text-md-center text-left">
            <h3>Immer einen Schritt voraus</h3>
          </Col>
        </Row>
        <Row>
          <Col md="8" sm="6">
            <div>
              <p>
                Gerne beraten wir Sie umfassend im Hinblick auf Ihren
                spezifischen Anwendungsfall und zeigen Ihnen, wie Sie Ihre
                Effizienz steigern können.
              </p>
              <p>
                Dabei verfügen wir über umfassende Projekt-Erfahrung
                insbesondere in der Automobilbranche. Dank unserer
                Hardware-Partner kann Sie unsere Software bei den
                unterschiedlichsten Aufgabenstellungen unterstützen. Unsere
                branchenführende Erkennungs-Software hilft Ihnen, Ihren
                Konkurrenten einen Schritt voraus zu bleiben.
              </p>
            </div>
            <ul style={{ marginTop: "3rem", marginLeft: "-3rem" }}>
              <li className={style.list_style}>
                Toolbox für Datenmanagement und Labelling
              </li>
              <li className={style.list_style}>
                Selbtslernende „Deep Learning“ Algorithmen
              </li>
              <li className={style.list_style}>Hohe Erkennungsraten</li>
              <li className={style.list_style}>Ausführliches Reporting</li>
              <li className={style.list_style}>
                Behalten Sie die Kontrolle und Steuerung über den wichtigen
                Qualitätssicherungsprozess
              </li>
            </ul>
          </Col>
          <Col md="4" sm="6" className="pb-3">
            <Slider />
          </Col>
        </Row>
      </Container>
      <ContactUsSection pathConsulting={false} />
      <OurPartnersSection />
    </Layout>
  )
}
